import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent implements OnInit {

  @Input() data?: UserOpinionInterface;
  @ViewChild('cardContent') cardContent: ElementRef;
  
  constructor() { }

  ngOnInit(): void {
    // console.log(this.data)
    // console.log(this.cardContent)
  }

}

export interface UserOpinionInterface {
  title:string,
  date: string,
  content:string,
  path:string
}