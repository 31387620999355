import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-card-detail-download-app',
  templateUrl: './card-detail-download-app.component.html',
  styleUrls: ['./card-detail-download-app.component.scss']
})
export class CardDetailDownloadAppComponent implements OnInit {

  @Input() data?: UserOpinionInterface;
  @ViewChild('cardContent') cardContent: ElementRef;
  
  constructor() { }

  ngOnInit(): void {
    // console.log(this.data)
    // console.log(this.cardContent)
    this.data = {
      title: "Tenvio App",
      date: new Date().toLocaleDateString(
        'es-ES',
        {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }
      ),
      content: "Descarga la app para tu plataforma favorita",
      path: "assets/img/tenvioLogo.svg"
    }
  }

}

export interface UserOpinionInterface {
  title:string,
  date: string,
  content:string,
  path:string
}