import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-legales',
  templateUrl: './legales.component.html',
  styleUrls: ['./legales.component.scss']
})
export class LegalesComponent implements OnInit {

  @ViewChild('banner') banner: ElementRef;
  
  constructor() { }

  ngOnInit(): void {
  }

}
