import { Injectable } from '@angular/core';
import { QueryDocumentSnapshot, DocumentData } from '@angular/fire/firestore/interfaces';


@Injectable()
export class GlobalService {

    constructor() {
    }

    public arrayToObject(docs: QueryDocumentSnapshot<DocumentData>[]) {
        return docs.map((snap: any) => {
            return {
                uid: snap.payload.doc.id,
                ...snap.payload.doc.data()
            };
        });
    }
}
