<section class="banner" #banner id="banner">
    <div class="row">
        <div class="col-md-12">
            <img src="../../assets/img/legales-banner.svg" alt="legales-banner" />
        </div>
        <div class="row col-md-12 menu justify-content-md-center text-center">
            <div class="col-2 child">
                <label><a href="/legales#tyc">Términos y condiciones</a></label>
            </div>
            <div class="col-2 child">
                <label><a href="/legales#politicas">Politicas de Privacidad</a></label>
            </div>
            <div class="col-2 child">
                <label> Responsabilidades</label>
            </div>
        </div>
    </div>
</section>

<section class="legales-textos" id="legales-textos">
    <div class="row">
        <div class="row container col-md-12 justify-content-md-center" id="tyc">
            <div class="col child">
                <label>Términos y condiciones</label>
                <br />
                <img src="../../assets/img/legales-linea.svg" alt="legales-linea" />
                <br />
                <label>Personas que pueden transportarlo de forma segura y confiable</label>
            </div>
            <div class="col child-contains">
                <label>
                    <p>
                        Por favor lea atentamente estos T&Eacute;RMINOS Y CONDICIONES DEL SERVICIO, dado que contienen
                        informaci&oacute;n importante sobre sus derechos, recursos y obligaciones. El presente incluye
                        limitaciones y exclusiones de responsabilidad, cl&aacute;usulas que determinan la
                        jurisdicci&oacute;n aplicable en caso de controversias, y obligaciones de cumplir con leyes y
                        regulaciones aplicables.
                    </p>

                    <p>
                        1.-DEFINICIONES &ldquo;Aplicaci&oacute;n&rdquo; significa, en su conjunto, cualquier
                        aplicaci&oacute;n m&oacute;vil de TENVIO o p&aacute;gina web proporcionada como parte del
                        Servicio.
                    </p>

                    <p>
                        &ldquo;Contenido&rdquo; significa texto, gr&aacute;ficos, im&aacute;genes, m&uacute;sica,
                        software. Audio, video, informaci&oacute;n u otros materiales.
                    </p>

                    <p>
                        &ldquo;T&eacute;rminos&rdquo; significa los presentes t&eacute;rminos y condiciones de la
                        Aplicaci&oacute;n.
                    </p>

                    <p>
                        &ldquo;TENVIO&rdquo; significa, a efectos de estos T&eacute;rminos, PLATAFORMA TENVIO SAS, una
                        sociedad an&oacute;nima simplificada, constituida en Argentina, con sede social en la calle
                        Narbondo 168 de la Ciudad de Jun&iacute;n, Provincia de Buenos Aires, inscrita en la
                        Direcci&oacute;n Provincial de Personas Jur&iacute;dicas.
                    </p>

                    <p>
                        &ldquo;Usuario&rdquo; es toda persona que utiliza el servicio a trav&eacute;s de cualquiera de
                        sus canales, registr&aacute;ndose en la plataforma y aceptando estos terminos y condiciones.-
                    </p>

                    <p>
                        &ldquo;Despachante&rdquo; son aquellos usuarios que env&iacute;o un bien de cualquier
                        &iacute;ndole, utilizando los servicios de TENVIO.
                    </p>

                    <p>
                        &ldquo;Viajeros&rdquo; son aquellos usuarios que realizar&aacute;n el transporte de los bienes
                        que el &ldquo;Despachante&rdquo; requiera a trav&eacute;s de la aplicaci&oacute;n.-
                    </p>

                    <p>
                        2.- INTRODUCCI&Oacute;N Los T&eacute;rminos abarcan el uso del sistema de TENVIO y de los
                        Servicios ofrecidos por TENVIO. Al acceder a aplicaciones, p&aacute;ginas web, Contenido, y
                        servicios puestos a disposici&oacute;n por TENVIO (los &ldquo;Servicios&rdquo;), usted acepta
                        cumplir con, y estar sujeto a, estos T&eacute;rminos y Condiciones, siendo &eacute;stos de
                        car&aacute;cter obligatorio y constituyendo un acuerdo legal vinculante entre usted y TENVIO.
                        Usted debe entender y aceptar todas las condiciones establecidas en estos T&eacute;rminos y en
                        las Pol&iacute;ticas de Privacidad as&iacute; como en todos los documentos incorporados a los
                        mismos previa su inscripci&oacute;n como usuario en TENVIO. TENVIO podr&aacute; poner fin de
                        inmediato a cualquiera de los Servicios respecto de usted, o, en general dejar de ofrecer o
                        denegar el acceso a los Servicios o cualquier parte de ellos, en cualquier momento y por
                        cualquier motivo, sin responsabilidad alguna para TENVIO.
                    </p>

                    <p>
                        TENVIO podr&aacute; modificar estos T&eacute;rminos relativos a los Servicios cuando lo
                        considere oportuno. Las modificaciones ser&aacute;n efectivas despu&eacute;s de la
                        publicaci&oacute;n por parte de TENVIO de dichos T&eacute;rminos actualizados en esta
                        ubicaci&oacute;n o las pol&iacute;ticas modificadas o condiciones suplementarias sobre el
                        Servicio aplicable. Su acceso o uso continuado de los Servicios despu&eacute;s de dicha
                        publicaci&oacute;n constituye su consentimiento a vincularse por los T&eacute;rminos y sus
                        modificaciones.
                    </p>

                    <p>
                        3.- LICENCIA PARA USAR LA APLICACI&Oacute;N A no ser que se establezca lo contrario TENVIO es la
                        &uacute;nica licenciataria en todo el territorio de la Rep&uacute;blica Argentina de los
                        derechos de propiedad intelectual y material de la Aplicaci&oacute;n. Todos los derechos se
                        encuentran debidamente reservados y registrados seg&uacute;n la normativa vigente en la
                        Rep&uacute;blica Argentina.
                    </p>

                    <p>
                        Sujeto al cumplimiento de estos T&eacute;rminos, TENVIO le otorga una licencia limitada, no
                        exclusiva, no sublicenciable, revocable, no transferible para: (I) el acceso y uso de las
                        Aplicaciones solo en relaci&oacute;n con su uso de los Servicios. TENVIO y sus licenciantes se
                        reservan cualquier derecho que no haya sido expresamente otorgado por el presente.
                    </p>

                    <p>3.1. Restricciones.</p>

                    <p>
                        Usted no podr&aacute;: (I) retirar cualquier nota de derechos de autor, marca registrada u otra
                        nota de propiedad de cualquier parte de los Servicios ni de las Aplicaciones; (II) reproducir,
                        modificar, preparar obras derivadas sobre los Servicios, distribuir, licenciar, arrendar,
                        revender, transferir, exhibir p&uacute;blicamente, presentar p&uacute;blicamente, transmitir,
                        retransmitir o explotar de otra forma los Servicios y/o la Aplicaci&oacute;n excepto como se
                        permita expresamente por TENVIO; (III) descompilar, realizar ingenier&iacute;a inversa o
                        desmontar los Servicios; (IV) enlazar, reflejar o enmarcar cualquier parte de los Servicios; (V)
                        causar o lanzar cualquier programa o script con el objeto de extraer, indexar, analizar o de
                        otro modo realizar prospecci&oacute;n de datos de cualquier parte de los Servicios o sobrecargar
                        o bloquear indebidamente la operaci&oacute;n y/o funcionalidad de cualquier aspecto de los
                        Servicios; o (VI) intentar obtener un acceso no autorizado o da&ntilde;ar cualquier aspecto de
                        los Servicios o de la Aplicaci&oacute;n o sus sistemas o redes relacionados.
                    </p>

                    <p>
                        4.- PRESTACI&Oacute;N DE LOS SERVICIOS Los Servicios disponibles a trav&eacute;s de nuestra
                        Aplicaci&oacute;n est&aacute;n dise&ntilde;ados para proporcionar al usuario servicios de
                        transporte de documentaci&oacute;n y/o cualquier tipo de producto con aptitud de ser
                        comercializado, de cuyo origen y licitud asume plena responsabilidad el
                        &ldquo;despachante&rdquo;, a trav&eacute;s de un servicio de transporte realizado por terceros
                        conductores de motos profesionales e independientes (&ldquo;Terceros Proveedores&rdquo;)
                        contratados por el usuario a trav&eacute;s de nuestro sitio.
                    </p>

                    <p>
                        Cada servicio es contratado en forma directa por el usuario de forma independiente sin
                        ning&uacute;n tipo de v&iacute;nculo entre el Tercero Proveedor y el usuario o el Tercero
                        Proveedor y TENVIO. En virtud de ello el usuario reconoce que TENVIO no tiene ninguna
                        responsabilidad por el servicio prestado por los Terceros Proveedores.
                    </p>

                    <p>
                        Al respecto, TENVIO s&oacute;lo se limita a conectar al cliente con el Tercero Proveedor para la
                        realizaci&oacute;n del Servicio de conformidad con las disposiciones de estos T&eacute;rminos.
                        Por lo tanto, los servicios prestados por TENVIO a sus usuarios se limitan a los Servicios y a
                        la gesti&oacute;n de cobro de los pagos a realizar por los usuarios a los Terceros Proveedores.
                    </p>

                    <p>
                        USTED RECONOCE QUE TENVIO NO PRESTA SERVICIOS DE TRANSPORTE DE NING&Uacute;N TIPO NI FUNCIONA
                        COMO UNA EMPRESA DE TRANSPORTES.
                    </p>

                    <p>
                        5.-CONDICIONES DE USO A trav&eacute;s de nuestra Aplicaci&oacute;n el usuario podr&aacute;
                        contratar directamente los servicios de un Tercero Proveedor que se encuentre previamente
                        registrado en la Aplicaci&oacute;n.-
                    </p>

                    <p>
                        El usuario se har&aacute; cargo de todos los costos derivados de la contrataci&oacute;n del
                        Tercero Proveedor.
                    </p>

                    <p>
                        El usuario entiende y reconoce que los Terceros Proveedores que le prestar&aacute;n el servicio
                        son terceros independientes y act&uacute;an por su propia cuenta utilizando para ello recursos
                        tales como casco, tel&eacute;fono m&oacute;vil, chaquetas, impermeables y plan de datos. Por lo
                        tanto, el usuario reconoce y acepta que TENVIO no es responsable por la prestaci&oacute;n del
                        servicio del Tercero Proveedor.
                    </p>

                    <p>
                        Al hacer uso de esta Aplicaci&oacute;n el usuario se compromete a respetar y seguir todos los
                        lineamientos establecidos en los presentes T&eacute;rminos.
                    </p>

                    <p>
                        Para utilizar nuestros Servicios el usuario debe ser mayor de dieciocho a&ntilde;os (18) o si se
                        tratare de una empresa deber&aacute; hacerlo a trav&eacute;s de su representante legal debiendo
                        en todos los casos inscribirse previamente en nuestra Aplicaci&oacute;n proporcionando los datos
                        solicitados, debiendo ser v&aacute;lidos y verdaderos.
                    </p>

                    <p>
                        6.- CUENTAS DE USUARIO Usted puede navegar por la Aplicaci&oacute;n y ver Contenido sin
                        registrarse. Sin embargo, para acceder a las funciones del Servicio primero deber&aacute; crear
                        una cuenta de usuario (&ldquo;Cuenta&rdquo;) con un nombre de usuario y una contrase&ntilde;a.
                        Cada vez que quiera acceder deber&aacute; utilizar los datos de dicha Cuenta. Luego
                        deber&aacute; introducir la trayectoria deseada y seguir los pasos hasta completar la
                        transacci&oacute;n, de &eacute;ste modo el usuario generar&aacute; una solicitud de servicio
                        (&ldquo;Solicitud de Servicio&rdquo;).
                    </p>

                    <p>
                        El registro de la Cuenta requiere que comunique a TENVIO determinada informaci&oacute;n personal
                        que incluir&aacute; (pero sin limitarse a) [su nombre, apellido direcci&oacute;n de correo
                        electr&oacute;nico, n&uacute;mero de tel&eacute;fono m&oacute;vil, as&iacute; como por lo menos
                        un m&eacute;todo de pago v&aacute;lido (bien una tarjeta de cr&eacute;dito o bien un socio de
                        pago aceptado)] (&ldquo;Contenido de Usuario&rdquo;). Usted se compromete a mantener la
                        informaci&oacute;n en su Cuenta de forma exacta, completa y actualizada. Si no mantiene la
                        informaci&oacute;n de Cuenta de forma exacta, completa y actualizada, incluso el tener un
                        m&eacute;todo de pago inv&aacute;lido o que haya vencido, podr&aacute; resultar en su
                        imposibilidad para acceder y utilizar los Servicios o en la resoluci&oacute;n por parte de
                        TENVIO de estos T&eacute;rminos. Usted es responsable de toda la actividad que ocurre en su
                        Cuenta y se compromete a mantener en todo momento de forma segura y secreta el nombre de usuario
                        y la contrase&ntilde;a de su Cuenta. A menos que TENVIO permita otra cosa por escrito, usted
                        solo puede poseer una Cuenta.
                    </p>

                    <p>
                        Usted no podr&aacute; autorizar a terceros a utilizar su Cuenta, asimismo no podr&aacute;
                        permitir a personas menores de 18 a&ntilde;os que reciban servicios de transporte privado de
                        Terceros Proveedores, No podr&aacute; ceder o transferir de otro modo su Cuenta a cualquier otra
                        persona o entidad. Usted acuerda cumplir con todas las leyes aplicables al utilizar los
                        Servicios y solo podr&aacute; utilizar los Servicios con fines leg&iacute;timos (p. ej., no para
                        el transporte de materiales ilegales o peligrosos). En el uso de los Servicios, no
                        causar&aacute; estorbos, molestias, incomodidades o da&ntilde;os a la propiedad, tanto al
                        Tercero Proveedor como a cualquier otra parte. En algunos casos, se le podr&aacute; requerir que
                        facilite un documento de identidad para el acceso o uso de los Servicios, y usted acepta que se
                        le podr&aacute; denegar el acceso o uso de los Servicios si se niega a facilitar tal documento
                        de identidad.
                    </p>

                    <p>
                        Selecci&oacute;n del Tercero Proveedor: Entre los Terceros Proveedores registrados en la
                        Aplicaci&oacute;n TENVIO m&aacute;s cercanos a la posici&oacute;n de inicio de la
                        prestaci&oacute;n del servicio, se activar&aacute; una alerta informando la Solicitud de
                        Servicio. El primer Tercero Proveedor que acepte dicha Solicitud de Servicio, ser&aacute; quien
                        realice el servicio, salvo que el usuario respectivo no lo aceptare. En ese caso se
                        repetir&aacute; el procedimiento descripto.
                    </p>

                    <p>
                        El usuario se obliga a no utilizar el Servicio para transportar dinero efectivo, cheques, bienes
                        il&iacute;citos, animales, armas de fuego, materiales peligrosos, combustibles, drogas y
                        estupefacientes, explosivos, joyer&iacute;a, objetos cuyo valor superen los dos mil quinientos
                        pesos $ 2.500 y en general cualquier bien cuyo transporte se encuentre prohibido por ley.
                    </p>

                    <p>
                        7.- ACCESO A LA RED Y DISPOSITIVOS Usted es responsable de obtener el acceso a la red de datos
                        necesario para utilizar los Servicios. Podr&aacute;n aplicarse las tarifas y tasas de datos y
                        mensajes de su red m&oacute;vil si usted accede o utiliza los Servicios desde un dispositivo
                        inal&aacute;mbrico y usted ser&aacute; responsable de dichas tarifas y tasas. Usted es
                        responsable de adquirir y actualizar el hardware compatible o los dispositivos necesarios para
                        acceder y utilizar los Servicios y Aplicaciones y cualquier actualizaci&oacute;n de estos.
                        TENVIO no garantiza que los Servicios, o cualquier parte de estos funcionen en cualquier
                        hardware o dispositivo particular. Adem&aacute;s, los Servicios podr&aacute;n ser objeto de
                        disfunciones o retrasos inherentes al uso de Internet y de las comunicaciones
                        electr&oacute;nicas.
                    </p>

                    <p>
                        8.-RESPONSABILIDAD El usuario es responsable del contenido transportado, respondiendo en forma
                        exclusiva por cualquier violaci&oacute;n legal, y eximiendo al Tercero Proveedor y a TENVIO de
                        cualquier responsabilidad derivada del producto transportado.
                    </p>

                    <p>
                        TENVIO NO SER&Aacute; RESPONSABLE DE DA&Ntilde;OS DIRECTOS NI INDIRECTOS, EJEMPLARES, PUNITIVOS,
                        CONSECUENCIALES O EMERGENTES, INCLUIDOS EL LUCRO CESANTE, LA P&Eacute;RDIDA DE DATOS,
                        OPORTUNIDADES DE NEGOCIO EL DA&Ntilde;O A LA PROPIEDAD, NI DE PERJUICIOS RELATIVOS, O EN
                        RELACI&Oacute;N CON, O DE OTRO MODO DERIVADOS DE CUALQUIER USO DE LOS SERVICIOS O DE LA
                        APLICACI&Oacute;N, INCLUSO AUNQUE TENVIO HAYA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS
                        DA&Ntilde;OS. TENVIO NO SER&Aacute; RESPONSABLE DE CUALQUIER DA&Ntilde;O, RESPONSABILIDAD O
                        P&Eacute;RDIDA QUE DERIVEN DE: (I) SU USO DE LOS SERVICIOS O SU INCAPACIDAD PARA ACCEDER O
                        UTILIZAR LOS SERVICIOS; O (ii) CUALQUIER TRANSACCI&Oacute;N O RELACI&Oacute;N ENTRE USTED Y
                        CUALQUIER TERCERO PROVEEDOR, AUNQUE TENVIO HUBIERA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS
                        DA&Ntilde;OS. TENVIO NO SER&Aacute; RESPONSABLE DEL RETRASO O DE LA FALTA DE EJECUCI&Oacute;N
                        DEL SERVICIO RESULTANTE DE CAUSAS QUE VAYAN M&Aacute;S ALL&Aacute; DEL CONTROL RAZONABLE DE
                        TENVIO.
                    </p>

                    <p>
                        USTED PODR&Aacute; UTILIZAR LOS SERVICIOS DE TENVIO PARA SOLICITAR Y PLANIFICAR SERVICIOS DE
                        TRANSPORTE PRIVADO DE BIENES CON TERCEROS PROVEEDORES, PERO ACEPTA QUE TENVIO NO TIENE
                        RESPONSABILIDAD ALGUNA HACIA USTED EN RELACI&Oacute;N CON CUALQUIER SERVICIO DE TRANSPORTE
                        PRIVADO DE BIENES, PRESTADO A USTED POR TERCEROS PROVEEDORES QUE NO SEA COMO SE HA ESTABLECIDO
                        EXPRESAMENTE EN ESTAS CONDICIONES.
                    </p>

                    <p>
                        LAS LIMITACIONES Y LA RENUNCIA EN EL PRESENTE APARTADO NO PRETENDEN LIMITAR LA RESPONSABILIDAD O
                        ALTERAR SUS DERECHOS COMO CONSUMIDOR QUE NO PUEDAN EXCLUIRSE SEG&Uacute;N LA LEY APLICABLE.
                    </p>

                    <p>
                        Los Terceros Proveedores no est&aacute;n empleados por TENVIO ni por ninguna de sus afiliadas y
                        no tienen ninguna subordinaci&oacute;n t&eacute;cnica, jur&iacute;dica ni econ&oacute;mica con
                        TENVIO, de modo tal que TENVIO no es responsable de ninguna forma por el servicio contratado por
                        el usuario al Tercero Proveedor, incluyendo pero no limit&aacute;ndose a el cumplimiento de la
                        orden de entrega, la realizaci&oacute;n del servicio sin defectos y en forma satisfactoria, los
                        da&ntilde;os producidos en el producto enviado, la p&eacute;rdida del objeto enviado, entre
                        otros.
                    </p>

                    <p>
                        Si el usuario contratista desea asegurar el objeto transportado hasta un valor de pesos tres mil
                        quinientos ($ 7.000.-) puede hacerlo abonando un cargo adicional proporcional que ser&aacute;
                        fijado en la plataforma.-
                    </p>

                    <p>
                        EN NING&Uacute;N CASO PLATAFORMA TENVIO S.A. SE HAR&Aacute; RESPONSABLE POR P&Eacute;RDIDA,
                        ROBO, DA&Ntilde;O TOTAL O PARCIAL DEL / LOS OBJETOS TRANSPORTADOS SI DICHO SEGURO NO ESTUVIESE
                        CONTRATADO.
                    </p>

                    <p>
                        9.- RENUNCIA LOS SERVICIOS SE PROPORCIONAN &ldquo;TAL CUAL&rdquo;. TENVIO RENUNCIA A TODA
                        DECLARACI&Oacute;N Y GARANT&Iacute;A, EXPRESA, IMPL&Iacute;CITA O ESTATUTARIA, NO EXPRESAMENTE
                        ESTABLECIDA EN ESTOS T&Eacute;RMINOS, INCLUIDAS LAS GARANT&Iacute;AS IMPL&Iacute;CITAS DE
                        COMERCIABILIDAD, IDONEIDAD PARA UN FIN PARTICULAR Y NO VIOLACI&Oacute;N. ADEM&Aacute;S, TENVIO
                        NO HACE DECLARACI&Oacute;N NI PRESTA GARANT&Iacute;A ALGUNA RELATIVA A LA FIABILIDAD,
                        PUNTUALIDAD, CALIDAD, IDONEIDAD O DISPONIBILIDAD DE LOS SERVICIOS O CUALESQUIERA DE LOS
                        SERVICIOS O BIENES SOLICITADOS A TRAV&Eacute;S DEL USO DE LOS SERVICIOS, O QUE LOS SERVICIOS NO
                        SER&Aacute;N INTERRUMPIDOS O ESTAR&Aacute;N LIBRES DE DEFECTOS. TENVIO NO GARANTIZA LA CALIDAD,
                        IDONEIDAD, SEGURIDAD O HABILIDAD DE LOS TERCEROS PROVEEDORES. USTED ACUERDA QUE TODO RIESGO
                        DERIVADO DE SU USO DE LOS SERVICIOS SER&Aacute; &Uacute;NICAMENTE SUYO, EN LA M&Aacute;XIMA
                        MEDIDA PERMITIDA POR LA LEY APLICABLE.
                    </p>

                    <p>
                        10.- INDEMNIDAD Usted acuerda mantener indemnes y responder frente a TENVIO y sus directores,
                        funcionarios, empleados y agentes por cualquier acci&oacute;n, reclamo, demanda, p&eacute;rdida,
                        responsabilidad y gasto (incluidos los gastos judiciales y honorarios de abogados) que deriven
                        de: (I) su uso de los Servicios o servicios o bienes obtenidos a trav&eacute;s de su uso de los
                        Servicios; (II) su incumplimiento o violaci&oacute;n de cualquiera de estos T&eacute;rminos;
                        (III) el uso por parte de TENVIO de su Contenido de Usuario; o (IV) su infracci&oacute;n de los
                        derechos de cualquier tercero, incluidos Terceros Proveedores.
                    </p>

                    <p>
                        11-. PAGO DEL SERVICIO El usuario es responsable del pago de la cantidad de dinero determinada
                        que se le informar&aacute; antes de la ejecuci&oacute;n de la transacci&oacute;n
                        (&ldquo;Cargos&rdquo;). La finalizaci&oacute;n de la transacci&oacute;n requiere el
                        consentimiento pleno del usuario contratista con respecto al Cargo determinado e informado.
                    </p>

                    <p>
                        Nuestra Aplicaci&oacute;n permite el c&aacute;lculo del Cargo a abonar por el usuario para los
                        Terceros Proveedores, mediante cualquiera de los sistemas de pago disponibles en nuestra
                        Aplicaci&oacute;n y elegidos por el usuario.
                    </p>

                    <p>
                        Despu&eacute;s de que haya recibido el servicio TENVIO facilitar&aacute; su pago de los Cargos
                        aplicables en nombre del Tercero Proveedor como agente de cobro del mismo. El pago de los Cargos
                        de dicha manera se considerar&aacute; como el pago efectuado directamente por el usuario al
                        Tercero Proveedor Los Cargos incluir&aacute;n los impuestos aplicables que se requieran por ley.
                        Los Cargos pagados por el usuario son definitivos y no reembolsables. Todos los Cargos son
                        pagaderos inmediatamente y el pago se facilitar&aacute; por TENVIO utilizando el m&eacute;todo
                        de pago preferido indicado en la Cuenta del usuario. Si se determina que el m&eacute;todo de
                        pago de la Cuenta del usuario ha caducado, es inv&aacute;lido o de otro modo no sirve para
                        efectivizar el pago, el usuario acepta que TENVIO como agente de cobro del Tercero Proveedor
                        utilice un m&eacute;todo de pago secundario si estuviere disponible en la Cuenta del usuario.
                    </p>

                    <p>
                        TENVIO podr&aacute; cuando lo considere oportuno proporcionar a determinados usuarios ofertas
                        promocionales y descuentos que pueden resultar en el cobro de diferentes importes por
                        &eacute;stos o similares servicios obtenidos a trav&eacute;s del uso de los Servicios.
                    </p>

                    <p>
                        Esta forma de pago est&aacute; destinada para compensar plenamente al Tercero Proveedor por los
                        servicios proporcionados. TENVIO no designa ninguna parte del pago como propina o
                        gratificaci&oacute;n al Tercero Proveedor. Las gratificaciones si las hubiere ser&aacute;n
                        voluntarias y a cargo del usuario.-
                    </p>

                    <p>
                        En caso de cancelaci&oacute;n de un pedido que ya haya sido tomado (con un mensajero en camino),
                        se cobrar&aacute; la tarifa m&iacute;nima de $145.- .(en el caso de motos) y $230 (en el caso de
                        autos). Si el pedido no estuviese tomado por ning&uacute;n mensajero al momento de la
                        cancelaci&oacute;n no se cobrar&aacute; importe alguno.
                    </p>

                    <p>
                        12.- INTERRUPCI&Oacute;N DE LA CUENTA Cada usuario es responsable de la plena utilizaci&oacute;n
                        de su Cuenta en forma exclusiva no pudiendo autorizar a terceros el uso de la misma. El usuario
                        que desee interrumpir su registro ante TENVIO y dejar de utilizar los Servicios puede hacerlo en
                        cualquier momento, sin costo alguno, sujeto al pago de las transacciones ya realizadas.
                    </p>

                    <p>
                        TENVIO se reserva el derecho de desactivar, bloquear o interrumpir el acceso del usuario al
                        Servicio sin previo aviso y sin responsabilidad alguna para TENVIO sin perjuicio de las acciones
                        legales pertinentes si verifica cualquier violaci&oacute;n legal a las disposiciones del
                        presente, si verifica la pr&aacute;ctica de actos fraudulentos a trav&eacute;s del uso de
                        nuestra Aplicaci&oacute;n ya sea en forma directa o indirecta, si el usuario causa alg&uacute;n
                        da&ntilde;o a TENVIO o cualquier otro usuario o a terceros.
                    </p>

                    <p>
                        En el caso de interrupci&oacute;n o cese de la Cuenta por cualquier raz&oacute;n TENVIO puede
                        mantener los datos y la historia de las transacciones realizadas por los usuarios almacenados.
                    </p>

                    <p>
                        13.- PRIVACIDAD TENVIO se obliga a mantener todos los datos de registro de manera segura y
                        confidencial, incluyendo direcciones de correo electr&oacute;nico, n&uacute;meros de accesos IP,
                        tel&eacute;fonos, y toda otra informaci&oacute;n personal brindada para el uso del Servicio.
                    </p>

                    <p>
                        El usuario autoriza a TENVIO para que tome las precauciones razonables t&eacute;cnicas y
                        organizacionales para prevenir la p&eacute;rdida, mal uso o alteraci&oacute;n de su
                        informaci&oacute;n personal.
                    </p>

                    <p>
                        TENVIO almacenar&aacute; toda la informaci&oacute;n personal que el usuario provea en servidores
                        seguros detr&aacute;s de firewalls protegidos con contrase&ntilde;a.
                    </p>

                    <p>
                        El usuario es responsable de mantener su contrase&ntilde;a y Contenido de Usuario de manera
                        confidencial.
                    </p>

                    <p>
                        14.- PROPIEDAD INTELECTUAL Los elementos y/o herramientas que se encuentran en la
                        Aplicaci&oacute;n est&aacute;n sujetos a derechos de propiedad intelectual de conformidad a la
                        legislaci&oacute;n vigente de la Rep&uacute;blica Argentina. A modo de ejemplo debe entenderse
                        como tales elementos a textos, software, guiones, gr&aacute;ficos, fotos, sonidos,
                        m&uacute;sica, videos, elementos interactivos y similares, marcas comerciales, marcas de
                        servicio, logotipos, etc.
                    </p>

                    <p>
                        Usted acuerda, en relaci&oacute;n con su uso del Servicio y de la Aplicaci&oacute;n que no:
                        Usar&aacute; nuestra Aplicaci&oacute;n ni el Servicio para violar los derechos de cualquier
                        persona o ente incluyendo sin limitaci&oacute;n su propiedad intelectual, privacidad, publicidad
                        o derechos contractuales; Postear, subir, publicar, someter o transmitir cualquier Contenido que
                        : (I) infrinja o viole la patente, copyright, marca, secreto comercial, derechos morales u otros
                        derechos de propiedad intelectual de terceros, o derechos de publicidad o privacidad; (II)
                        viola, o incentiva cualquier conducta que viole cualquier ley o regulaci&oacute;n aplicable o
                        suscita responsabilidad civil; (III) sea fraudulento, falso, err&oacute;neo o enga&ntilde;oso;
                        (IV) sea difamatorio, obsceno, pornogr&aacute;fico, vulgar u ofensivo; (V) promueva la
                        discriminaci&oacute;n, intolerancia, racismo, odio, acoso o da&ntilde;o hacia cualquier
                        individuo o grupo; (VI) sea violento o amenazante o promueva violencia o acciones que sean
                        amenazantes hacia cualquier otra persona; o (VII) promueva actividades o substancias ilegales o
                        da&ntilde;osas.
                    </p>

                    <p>15.- OTRAS DISPOSICIONES a) Notificaciones</p>

                    <p>
                        TENVIO podr&aacute; notificar por medio de una notificaci&oacute;n general en los Servicios,
                        mediante un correo electr&oacute;nico enviado a su direcci&oacute;n electr&oacute;nica en su
                        Cuenta o por comunicaci&oacute;n escrita enviada a su direcci&oacute;n, seg&uacute;n lo
                        dispuesto en su Cuenta.
                    </p>

                    <p>b) Cesi&oacute;n</p>

                    <p>
                        Usted no puede ceder o transferir esos T&eacute;rminos, en virtud de la ley o de otra manera,
                        sin el consentimiento escrito previo de TENVIO. Cualquier intento para ceder o transferir esos
                        T&eacute;rminos sin el consentimiento de TENVIO ser&aacute; nulo y sin efecto. TENVIO puede
                        ceder o transferir y modificar estos T&eacute;rminos a su absoluta discreci&oacute;n, sin
                        restricciones. Sujeto a lo anterior, esos T&eacute;rminos obligar&aacute;n y se
                        entender&aacute;n en beneficios de las partes, sus sucesores y cesionarios autorizados.
                    </p>

                    <p>c) No Renuncia</p>

                    <p>
                        La falta de ejercicio por parte de TENVIO de cualquier derecho o disposici&oacute;n de esos
                        T&eacute;rminos no constituir&aacute; una renuncia de ese derecho o disposici&oacute;n. La
                        renuncia de cualquiera de esos derechos o disposiciones solo ser&aacute; eficaz si es por
                        escrito y firmada por un representante autorizado de TENVIO. Salvo como expuesto expresamente
                        aqu&iacute; dentro, el ejercicio de cualquier parte de cualquier de sus recursos legales bajo
                        esos T&eacute;rminos ser&aacute; sin perjuicio para sus otros recursos legales bajo estos
                        T&eacute;rminos o de otra manera.
                    </p>

                    <p>
                        16.- LEGISLACI&Oacute;N APLICABLE &ndash; ARBITRAJE Cualquier desacuerdo, controversia o reclamo
                        que surja de o con relaci&oacute;n a los presentes T&eacute;rminos, sea de origen contractual u
                        otro, incluyendo sin limitaci&oacute;n, cualquier desacuerdo con respecto a la validez,
                        terminaci&oacute;n, cumplimiento o incumplimiento de los presentes T&eacute;rminos, ser&aacute;
                        resuelto de manera definitiva mediante arbitraje voluntario privado practicado por mediador
                        designado incluido en la matr&iacute;cula de mediadores prejudiciales del Colegio de Abogados de
                        la Provincia de Buenos Aires, de acuerdo con la reglamentaci&oacute;n vigente en ese momento
                        para el arbitraje de derecho. El lugar del arbitraje ser&aacute; la ciudad de Jun&iacute;n,
                        Provincia de Buenos Aires, Argentina. El procedimiento arbitral se llevar&aacute; a cabo en
                        espa&ntilde;ol. El laudo emitido ser&aacute; definitivo y concluyente y el laudo podr&aacute;
                        ser sometido ante cualquier tribunal con jurisdicci&oacute;n para su ejecuci&oacute;n.
                    </p>

                    <p>
                        17.- ACUERDO COMPLETO Los presentes T&eacute;rminos y nuestra Pol&iacute;tica de Privacidad
                        constituyen el acuerdo completo entre los usuarios y TENVIO en relaci&oacute;n al uso que usted
                        haga de nuestra Aplicaci&oacute;n y de los Servicios ofrecidos.
                    </p>

                    <p>
                        Si se determinara que alguna disposici&oacute;n de estos T&eacute;rminos de uso pudiera ser
                        inaplicable o inv&aacute;lida, dicha disposici&oacute;n ser&aacute; nula, pero su nulidad no
                        afectar&aacute; a las dem&aacute;s disposiciones del presente.-
                    </p>

                    <p>
                        TENVIO podr&aacute; modificar las condiciones relativas a los Servicios y a los presentes
                        T&eacute;rminos cuando lo considere oportuno.
                    </p>
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row container col-md-12 justify-content-md-center pt-0" id="politicas">
            <div class="col child">
                <label>Políticas de Privacidad</label>
                <br />
                <img src="../../assets/img/legales-linea.svg" alt="legales-linea" />
            </div>
            <div class="col child-contains">
                <label>
                    <p>
                        El presente documento detalla las pol&iacute;ticas adoptadas por Plataforma Tenvio
                        SAS(&ldquo;TENVIO&rdquo;), con CUIT 30-71691562-6 y domicilio en Narbonodo N&deg; 168, de la
                        ciudad de Jun&iacute;n, Pcia. de Buenos Aires, en materia de privacidad y protecci&oacute;n de
                        los datos de cualquier persona (en adelante &ldquo;Usuario&rdquo; o en plural
                        &ldquo;Usuarios&rdquo;) que desee acceder y/o utilizar la p&aacute;gina web
                        https://www.TENVIO.com.ar/ o su app en
                        <a href="https://play.google.com/store/apps/details?id=com.TENVIO&hl=es_PA">Playstore</a>
                        o en Apple Store (la &ldquo;Plataforma&rdquo;). De acuerdo con lo establecido por la Ley N&deg;
                        25.326 de Protecci&oacute;n de Datos Personales, se informa que el suministro de cualquier dato
                        de car&aacute;cter personal a trav&eacute;s de los distintos formularios de la Plataforma,
                        implica aceptar y consentir expresamente el tratamiento de los mismos en los t&eacute;rminos
                        indicados en la presente Pol&iacute;tica de Privacidad.
                    </p>
                    <p>Recolecci&oacute;n de datos generales</p>
                    <p>
                        El acceso y/o utilizaci&oacute;n de la Plataforma se puede realizar de forma totalmente
                        an&oacute;nima y nuestros servidores &uacute;nicamente recolectan y conservan una serie de datos
                        p&uacute;blicos y de libre acceso, con fines estad&iacute;sticos. Esos datos son: el nombre del
                        dominio o direcci&oacute;n de IP (Protocolo de Internet), el proveedor de acceso a Internet, la
                        fecha y hora de acceso a la Plataforma y la direcci&oacute;n desde la que parti&oacute; el
                        hiperv&iacute;nculo que dirige la Plataforma. Esta recolecci&oacute;n de datos no permite la
                        identificaci&oacute;n del Usuario de la Plataforma.
                    </p>
                    <p>
                        En relaci&oacute;n a lo anteriormente mencionado, y de acuerdo a las mejores pr&aacute;cticas de
                        la industria, se utilizan peque&ntilde;os ficheros de datos, denominados &ldquo;cookies&rdquo;,
                        que se generan en la computadora del usuario y que permiten obtener la siguiente
                        informaci&oacute;n: la fecha y hora de la &uacute;ltima vez en que se visit&oacute; la
                        Plataforma, el dise&ntilde;o de contenidos que se escogi&oacute; en visitas anteriores a la
                        Plataforma, acciones que realiz&oacute; en la plataforma y elementos de seguridad que
                        intervienen en el control de acceso a las &aacute;reas restringidas.
                    </p>
                    <p>
                        El Usuario tiene la opci&oacute;n de impedir la generaci&oacute;n de &ldquo;cookies&rdquo;
                        mediante la selecci&oacute;n de la correspondiente opci&oacute;n en su programa navegador de
                        Internet. Sin embargo, la desactivaci&oacute;n de las mismas podr&iacute;a eventualmente
                        interferir con el correcto funcionamiento de la Plataforma
                    </p>
                    <p>Datos creados durante el uso de nuestros servicios.&nbsp;</p>
                    <p>Incluye lo siguiente:</p>
                    <p>
                        Datos de ubicaci&oacute;n (Usuario viajero): TENVIO recopila esta informaci&oacute;n cuando la
                        app de TENVIO se ejecuta en primer plano (app abierta y en pantalla) o en segundo plano (app
                        abierta, pero no en pantalla) de su dispositivo m&oacute;vil.
                    </p>
                    <p>
                        Datos de ubicaci&oacute;n (usuarios y clientes). Si los usuarios y los clientes lo permiten,
                        recopilamos datos de ubicaci&oacute;n precisos o aproximados de sus dispositivos m&oacute;viles.
                        TENVIO recopila esta informaci&oacute;n desde el momento en que se pide una carga o un
                        env&iacute;o hasta que se completa, y cada vez que la app se ejecuta en primer plano de un
                        dispositivo m&oacute;vil. Usamos estos datos a fin de mejorar el uso de nuestras apps, p. ej.,
                        para optimizar la precisi&oacute;n de los puntos de partida, activar las funciones de seguridad,
                        y prevenir y detectar el fraude.
                    </p>
                    <p>
                        TENVIO recopila los datos de ubicaci&oacute;n del usuario viajero y chofer y los vincula con la
                        cuenta del usuario durante el viaje, incluso si el usuario no activ&oacute; esta funci&oacute;n
                        en su dispositivo. Esto nos permite ofrecer servicios al usuario, como la emisi&oacute;n de
                        recibos y el soporte al cliente.
                    </p>
                    <p>
                        Informaci&oacute;n de transacciones: Recopilamos datos de las transacciones relacionadas con el
                        uso de nuestros servicios, lo que incluye el tipo de servicio solicitado o prestado, fecha y
                        hora de la prestaci&oacute;n, detalles de la carga, informaci&oacute;n de entrega, monto
                        cobrado, distancia recorrida y m&eacute;todo de pago. Asimismo, si alguien usa un c&oacute;digo
                        promo, podemos asociar su nombre con esa persona.
                    </p>
                    <p>
                        Datos de uso: Recopilamos informaci&oacute;n sobre c&oacute;mo los usuarios interact&uacute;an
                        con nuestros servicios. Esto incluye la fecha y la hora de acceso, funciones de la app o
                        p&aacute;ginas vistas, fallos de la app y otras actividades del sistema, tipo de navegador, y
                        sitios o servicios de terceros usados antes de interactuar con nuestros servicios. En algunos
                        casos recopilamos estos datos a trav&eacute;s de cookies, p&iacute;xeles, etiquetas y
                        tecnolog&iacute;as similares que crean y mantienen identificadores exclusivos.
                    </p>

                    <p>
                        Datos del dispositivo: Podemos recopilar datos sobre los dispositivos usados para acceder a
                        nuestros servicios, lo que incluye modelos de hardware, direcci&oacute;n IP, sistemas operativos
                        y versiones, software, idiomas preferidos, identificadores &uacute;nicos del dispositivo,
                        identificadores de publicidad, n&uacute;meros de serie e informaci&oacute;n de movimiento del
                        dispositivo o de las redes m&oacute;viles.
                    </p>
                    <p>
                        Datos de comunicaci&oacute;n: Permitimos que los usuarios se comuniquen entre s&iacute; y con
                        TENVIO a trav&eacute;s de las apps m&oacute;viles y de los sitios web de TENVIO. P. ej.,
                        permitimos la comunicaci&oacute;n entre viajeros y usuarios, a trav&eacute;s de llamadas, SMS,
                        Whatsapp o env&iacute;o de otros archivos (por lo general, sin revelar sus n&uacute;meros de
                        tel&eacute;fono). Para brindar este servicio, TENVIO recibe ciertos datos relacionados con las
                        llamadas, SMS u otras comunicaciones, como su fecha, hora y contenido. TENVIO tambi&eacute;n
                        puede usar esta informaci&oacute;n por razones de seguridad y para brindar servicios de soporte
                        al cliente (incluso a fin de resolver disputas entre usuarios), realizar an&aacute;lisis y
                        mejorar los productos y servicios.
                    </p>
                    <p>C&oacute;mo usamos los datos personales</p>
                    <p>
                        TENVIO recopila y usa informaci&oacute;n para que los servicios de transporte y de entregas,
                        as&iacute; como otros productos y servicios, sean confiables y convenientes. Tambi&eacute;n
                        usamos los datos recopilados para realizar lo siguiente:
                    </p>
                    <p>Para mejorar la seguridad y la protecci&oacute;n de los usuarios y los servicios</p>
                    <p>Para soporte al cliente</p>
                    <p>Para la investigaci&oacute;n y el desarrollo</p>
                    <p>Permitir la comunicaci&oacute;n entre usuarios</p>
                    <p>Enviar comunicaciones no comerciales y de marketing a los usuarios</p>
                    <p>Para temas relacionados con procedimientos legales</p>

                    <p>
                        TENVIO no vende ni divulga datos personales del usuario a terceros con fines de marketing
                        directo, salvo que los usuarios den su consentimiento.
                    </p>
                    <p><br /></p>
                    <p>Configuraci&oacute;n de privacidad</p>
                    <p>
                        El men&uacute; de configuraci&oacute;n en la app de TENVIO brinda a los usuarios la capacidad de
                        establecer o de actualizar las preferencias para compartir la ubicaci&oacute;n y para recibir
                        notificaciones de TENVIO. A continuaci&oacute;n se brinda informaci&oacute;n sobre estas
                        configuraciones, c&oacute;mo establecerlas o cambiarlas, y qu&eacute; sucede si se desactivan.
                    </p>
                    <p>Datos de ubicaci&oacute;n</p>
                    <p>
                        TENVIO usa los Servicios de ubicaci&oacute;n de los dispositivos de los usuarios para que puedan
                        ver y realizar cargas f&aacute;cilmente cuando los necesiten. Estos datos nos ayudan a mejorar
                        nuestros servicios, como los inicios de viaje, navegaci&oacute;n y soporte al cliente.
                    </p>
                    <p>
                        Los usuarios pueden permitir o impedir que TENVIO recopile datos de ubicaci&oacute;n de sus
                        dispositivos m&oacute;viles a trav&eacute;s del men&uacute; Configuraci&oacute;n &gt; Privacidad
                        en la app de TENVIO. Los usuarios y los clientes pueden activar o desactivar dichas
                        recopilaciones a trav&eacute;s de la configuraci&oacute;n de su dispositivo m&oacute;vil.
                    </p>
                    <p>Compartir ubicaci&oacute;n en vivo (usuarios)</p>
                    <p>
                        Los usuarios que permitieron que se recopilen los datos de ubicaci&oacute;n de su dispositivo
                        m&oacute;vil tambi&eacute;n pueden autorizar que TENVIO los comparta con el usuario generador de
                        la carga desde el momento en que pide el viaje hasta que se termina. Esto puede ayudar a mejorar
                        los inicios de viaje, tanto para los viajeros como para los usuarios, particularmente en zonas
                        concurridas.
                    </p>
                    <p>
                        Los usuarios pueden activar o desactivar esta funci&oacute;n a trav&eacute;s del men&uacute;
                        Configuraci&oacute;n &gt; Privacidad en la app de TENVIO.
                    </p>
                    <p>Datos compartidos con el servicio de emergencias (usuarios)</p>
                    <p>
                        Los usuarios que permitieron a TENVIO recopilar datos de ubicaci&oacute;n de su dispositivo
                        m&oacute;vil tambi&eacute;n pueden activar la funci&oacute;n para compartir datos en caso de
                        emergencia con la polic&iacute;a, bomberos y servicios de ambulancia. Estos detalles incluyen la
                        ubicaci&oacute;n aproximada en el momento en que se realiz&oacute; la llamada de emergencia,
                        marca, modelo, color y matr&iacute;cula del auto, nombre y n&uacute;mero de tel&eacute;fono del
                        usuario, punto de partida y destino, y nombre del usuario viajero.
                    </p>
                    <p>
                        Los usuarios pueden activar o desactivar esta funci&oacute;n a trav&eacute;s del men&uacute;
                        Configuraci&oacute;n &gt; Privacidad o en el Centro de seguridad de la app de TENVIO.
                    </p>
                    <p>Permisos del dispositivo</p>
                    <p>
                        La mayor&iacute;a de las plataformas de dispositivos m&oacute;viles (iOS, Android, etc.)
                        definieron ciertos tipos de datos de los dispositivos a los que las apps no pueden acceder sin
                        autorizaci&oacute;n del propietario y estas plataformas tienen diferentes m&eacute;todos para
                        obtenerla. En los dispositivos con iOS, los usuarios reciben una notificaci&oacute;n la primera
                        vez que la app de TENVIO solicita permiso para acceder a ciertos tipos de datos y tienen la
                        opci&oacute;n de otorgarlo o de rechazarlo. En los dispositivos con Android, se les
                        notificar&aacute; qu&eacute; permisos necesita la app de TENVIO antes de que la utilicen por
                        primera vez y el uso de la app constituir&aacute; dicho consentimiento.
                    </p>
                    <p><br /></p>
                    <p>Derecho de acceso a datos de personales</p>
                    <p>
                        El acto de proporcionar los datos de car&aacute;cter personal s&oacute;lo se realizar&aacute; de
                        forma voluntaria.
                    </p>
                    <p>
                        El suministro de datos de car&aacute;cter personal se producir&aacute; de forma libre y
                        voluntaria, con una finalidad determinada, expl&iacute;cita y leg&iacute;tima. El Usuario debe
                        registrar y/o transmitir informaci&oacute;n veraz, correcta, completa y mantenerla siempre
                        actualizada y TENVIO se reserva el derecho de iniciar un procedimiento de validaci&oacute;n de
                        datos y documentaci&oacute;n.
                    </p>
                    <p>
                        Se notifica expresamente al Usuario que brindar datos inexactos o falsos podr&aacute; ocasionar
                        da&ntilde;os y perjuicios al resto de los Usuarios, a terceros y/o a TENVIO, de los cuales el
                        Usuario ser&aacute; responsable.
                    </p>
                    <p>
                        Siempre que TENVIO solicite datos de car&aacute;cter personal informar&aacute; al titular de la
                        finalidad de la recolecci&oacute;n y el tratamiento de los mismos y de la posibilidad y forma de
                        acceso a los mismos, para su modificaci&oacute;n o cancelaci&oacute;n, conforme lo dispuesto en
                        la Ley N&ordm; 25.326 de Protecci&oacute;n de Datos Personales. Los Usuarios tendr&aacute;n
                        derecho a ratificar o modificar los datos personales ingresados y/o solicitar su
                        eliminaci&oacute;n.
                    </p>
                    <p>
                        TENVIO no se hace responsable de los datos personales proporcionados a sitios Web de terceros a
                        los que el Usuario pueda acceder a trav&eacute;s de enlaces en la Plataforma.
                    </p>
                    <p><br /></p>
                    <p>Confidencialidad</p>
                    <p>
                        TENVIO har&aacute; todo lo que est&eacute; a su alcance para mantener la confidencialidad y la
                        seguridad de los datos personales depositados por los Usuarios. Sin embargo, puede ocurrir que,
                        en virtud de &oacute;rdenes judiciales o de regulaciones legales, TENVIO se vea compelida a
                        revelar informaci&oacute;n a las autoridades o a terceras partes bajo ciertas circunstancias, o
                        bien en casos que terceras partes puedan interceptar o acceder a cierta informaci&oacute;n o
                        transmisiones de datos en cuyo caso TENVIO no responder&aacute; por la informaci&oacute;n que
                        sea revelada.
                    </p>
                    <p>Custodia y seguridad del tratamiento de los datos personales</p>
                    <p>
                        TENVIO emplear&aacute; medidas t&eacute;cnicas y organizativas adecuadas, de acuerdo a los
                        criterios de buenas pr&aacute;cticas de la industria, para proteger y mantener la
                        confidencialidad de los datos personales de los usuarios del sitio contra la p&eacute;rdida,
                        destrucci&oacute;n, modificaci&oacute;n, difusi&oacute;n y acceso no autorizado.
                    </p>
                    <p>
                        Para mayor seguridad en el tratamiento de los datos personales de los Usuarios, estos
                        podr&aacute;n ser transferidos a servidores ubicados en otros pa&iacute;ses, donde ser&aacute;n
                        almacenados, lo que el Usuario acepta. Una vez transferidos los datos a otro pa&iacute;s, las
                        normas vigentes en ese pa&iacute;s tambi&eacute;n pasan a ser aplicables, incluyendo, pero no
                        limit&aacute;ndose a las normas que atienden al acceso a datos personales por parte de las
                        autoridades. En consecuencia, cuando sea legal o judicialmente exigible por las autoridades de
                        este pa&iacute;s, los titulares aceptan y consienten que TENVIO permita el acceso a esas
                        entidades a los datos almacenados.
                    </p>
                    <p>
                        Sin perjuicio a las medidas de seguridad tomadas por TENVIO, como ser el uso de sistemas de
                        encriptaci&oacute;n y &ldquo;cortafuegos&rdquo; (firewalls), el Usuario acepta y reconoce que la
                        transmisi&oacute;n de los datos se realiza a trav&eacute;s de una red abierta -Internet- no
                        pudiendo TENVIO garantizar la seguridad de su circulaci&oacute;n, por lo que es consciente de
                        que los datos pueden ser vistos y/o utilizados por terceros no autorizados.
                    </p>
                    <p>
                        Adicionalmente, TENVIO no se hace responsable de la falta y/o p&eacute;rdida de almacenamiento
                        de mensajes, datos o contenidos, sobre todo debido a, pero no limitado por, fallos de la red,
                        fallos inform&aacute;ticos, errores y/o fallos del proveedor de servicios en l&iacute;nea.
                    </p>
                    <p>
                        Recopilaci&oacute;n y uso de la informaci&oacute;n de los Usuarios de-identificada
                        (an&oacute;nima).
                    </p>
                    <p>
                        Se informa al Usuario que la informaci&oacute;n cargada y/o registrada a trav&eacute;s de la
                        Plataforma, (la &ldquo;Informaci&oacute;n&rdquo;), podr&aacute; ser objeto de tratamiento
                        automatizado por parte de TENVIO para su de-identificaci&oacute;n y convertida en an&oacute;nima
                        o de otro modo tornada no identificable para ser incorporada a bases de datos de TENVIO,
                        pudiendo ser utilizada para todo tipo de fines. En consecuencia, cada vez que el Usuario brinde
                        Informaci&oacute;n a TENVIO, acepta y presta su consentimiento para que &eacute;sta sea
                        utilizada conforme a lo arriba mencionado, autorizando a que la misma sea de-identificada para
                        que sea tratada, almacenada, recopilada o cedida conforme lo aqu&iacute; previsto.
                    </p>
                    <p>
                        Asimismo, se informa al Usuario que TENVIO podr&aacute; acceder a la Informaci&oacute;n para
                        hacer m&eacute;tricas y an&aacute;lisis del uso de la Plataforma por el Usuario con fines de
                        mejora de la Plataforma y la experiencia del Usuario.
                    </p>
                    <p><br /></p>
                    <p>Herramientas de Terceros</p>
                    <p>
                        La Plataforma utiliza herramientas de terceros para optimizar la experiencia de los Usuarios.
                        Cada uno de nuestros proveedores de herramientas tiene su propia pol&iacute;tica de privacidad,
                        que describe sus pr&aacute;cticas de recolecci&oacute;n y manejo de informaci&oacute;n. Estos
                        proveedores podr&iacute;an usar cookies o tecnolog&iacute;as similares para recopilar
                        informaci&oacute;n. A continuaci&oacute;n, TENVIO provee al Usuario hiperv&iacute;nculos a las
                        pol&iacute;ticas de privacidad de dichos proveedores. Esta informaci&oacute;n podr&iacute;a
                        verse modificada en el transcurso del tiempo. TENVIO har&aacute; sus mayores esfuerzos para
                        mantener esta informaci&oacute;n actualizada, pero puede producirse alg&uacute;n retraso, por lo
                        que ser&aacute; responsabilidad del Usuario mantenerse al d&iacute;a con las pol&iacute;ticas de
                        privacidad vigentes de cada proveedor de herramientas.
                    </p>
                    <p>Nuestros proveedores de herramientas son:</p>
                    <p>Google Analytics</p>
                    <p>Google Seach Console</p>
                    <p>Google PlayStore</p>
                    <p>Google Inc.</p>
                    <p>Facebook Inc.</p>
                    <p>Mailchimp</p>
                    <p><br /></p>
                    <p>
                        Derechos de acceso, cancelaci&oacute;n, rectificaci&oacute;n y oposici&oacute;n de los titulares
                        de los datos
                    </p>
                    <p>
                        EL TITULAR DE SUS DATOS PERSONALES TIENE LA FACULTAD DE EJERCER EL DERECHO DE ACCESO A LOS
                        MISMOS EN FORMA GRATUITA A INTERVALOS NO INFERIORES A SEIS MESES, SALVO QUE SE ACREDITE UN
                        INTER&Eacute;S LEG&Iacute;TIMO AL EFECTO CONFORME LO ESTABLECIDO EN EL ART&Iacute;CULO 14,
                        INCISO 3 DE LA LEY N&ordm; 25.326 (de conformidad con lo previsto por la Disposici&oacute;n
                        DNPDP 10/2008).
                    </p>
                    <p>
                        LA DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, ORGANO DE CONTROL DE LA LEY N&ordm;
                        25.326, TIENE LA ATRIBUCI&Oacute;N DE ATENDER LAS DENUNCIAS Y RECLAMOS QUE SE INTERPONGAN CON
                        RELACI&Oacute;N AL INCUMPLIMIENTO DE LAS NORMAS SOBRE PROTECCI&Oacute;N DE DATOS PERSONALES (de
                        conformidad con lo previsto por la Disposici&oacute;n DNPDP 10/2008).
                    </p>
                    <p>
                        A su vez, el titular de datos tiene la facultad de ejercer el derecho de supresi&oacute;n y el
                        derecho de rectificaci&oacute;n de sus datos personales, en los t&eacute;rminos establecidos en
                        los art&iacute;culos 6 y 16 de la Ley N&deg; 25.326.
                    </p>
                    <p>
                        TENVIO garantiza en todo caso al titular de los datos el ejercicio de los derechos de acceso,
                        rectificaci&oacute;n, cancelaci&oacute;n, informaci&oacute;n y oposici&oacute;n, en los
                        t&eacute;rminos dispuestos en la legislaci&oacute;n vigente. Por ello, de conformidad con lo
                        dispuesto en la Ley N&ordm; 25.326 podr&aacute; ejercer sus derechos remitiendo una solicitud
                        expresa, junto a una copia de su DNI, a trav&eacute;s de los siguientes medios:
                    </p>
                    <p><b>E-Mail:</b> sm.tenvio@gmail.com</p>
                    <p>
                        <b>Direcci&oacute;n (correo postal):</b> Narbondo N&deg; 168 de la ciudad de Jun&iacute;n,
                        Provincia de Buenos Aires.
                    </p>
                </label>
            </div>
        </div>
    </div>
</section>
