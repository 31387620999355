export const data = [
    {
        "title": "Tenés un emprendimiento y necesitas agilizar la entrega de tus ventas",
        "subTitle": "Publicalo en Tenvío, conocé quienes están por viajar, sus destinos y tarifas. Envía de manera segura sin pagar de más. Rápido, seguro y confiable. "
    },
    {
        "title": "Compraste por Marketplace ese mueble antiguo que tanto buscabas y no sabés ¿quién puede llevarlo hasta tu casa?",
        "subTitle": "Publicalo en Tenvío, conocé quienes están por viajar, sus destinos y tarifas. Envía de manera segura sin pagar de más. Rápido, seguro y confiable. "
    },
    {
        "title": "¿Querés envíar una encomienda a Rosario para tus hijos?",
        "subTitle": "Publicalo en Tenvío, conocé quienes están por viajar, sus destinos y tarifas. Envía de manera segura sin pagar de más. Rápido, seguro y confiable. "
    },
    {
        "title": "¿Querés sorprender a tu abuela con sus chocolates favoritos?",
        "subTitle": "Publicalo en Tenvío, conocé quienes están por viajar, sus destinos y tarifas. Enviá de manera segura sin pagar de más. Rápido, seguro y confiable."
    },
    {
        "title": "Te olvidaste las llaves en lo de un amigo y necesitas que te las alcancen...",
        "subTitle": "Publicalo en Tenvío, conocé quienes están por viajar, sus destinos y tarifas. Enviá de manera segura sin pagar de más. Rápido, seguro y confiable. "
    },
    {
        "title": "¿Te mudas y necesitas un flete para hacer la mudanza...?",
        "subTitle": "Publicalo en Tenvío, conocé quienes están por viajar, sus destinos y tarifas. Envía de manera segura sin pagar de más. Rápido, seguro y confiable. "
    },
    {
        "title": "Tenés una farmacia y te falta quien entregue los medicamentos a domicilio",
        "subTitle": "Publicalo en Tenvío, conocé quienes están por viajar, sus destinos y tarifas. Envía de manera segura sin pagar de más. Rápido, seguro y confiable. "
    },
    {
        "title": "Te salió un viaje de trabajo a Capital y tenés espacio extra en el auto.",
        "subTitle": "Publicá tu destino en Tenvío, compartilo con la comunidad. Optimizá tu tiempo y ganá dinero. "
    },
    {
        "title": "Organizaste un viaje de fin de semana a la Costa con amigos y necesitas llevarte unos pesos extras.",
        "subTitle": "Publicá tu destino en Tenvío, compartilo con la comunidad. Optimizá tu tiempo y ganá dinero. "
    },
    {
        "title": "Te toca viajar a La Plata por un trámite familiar y querés optimizar tu tiempo y no tener que pagar el combustible...",
        "subTitle": "Publicá tu destino en Tenvío, compartilo con la comunidad. Optimizá tu tiempo y ganá dinero. "
    },
    {
        "title": "Viajas para tu ciudad y tenés lugar y tiempo para hacer entregas…",
        "subTitle": "Publicá tu destino en Tenvío, compartilo con la comunidad. Optimizá tu tiempo y ganá dinero. "
    }
]
