<div class="card_avatar_mobile web-row">
    <img [src]=data.path>
    <div class="sub-card">

        <label class="card-title"> {{data.title}} </label>
        <label class="card-date"> {{data.date}} </label>

        <label class="card-content" innerHtml='"{{data.content}}"'></label>

    </div>

</div>

<div class="card_avatar_mobile mobile-row">
    <img [src]=data.path>

    <div class="row">
        <div class="col-1 sub-card w-50">

        </div>

        <div class="col-8 sub-card">
            <div>
                <label class="card-title"> {{data.title}} </label>
                <label class="card-date"> {{data.date}} </label>

            </div>
            <div class="sub-card-content">
                <label class="card-content" innerHtml="{{data.content}}"></label>

            </div>



        </div>

        <div class="col-1 sub-card w-50">

        </div>
    </div>


</div>