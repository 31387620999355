import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { axiosApi } from '../../utils/api/axiosApi';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-desuscribirse',
  templateUrl: './desuscribirse.component.html',
  styleUrls: ['./desuscribirse.component.scss']
})
export class DesuscribirseComponent implements OnInit {

  myForm: FormGroup;
  emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  isData: boolean;
  hasReloaded: boolean = false;

  constructor(
    private fb: FormBuilder,
    private viewportScroller: ViewportScroller
  ) {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    });
  }

  ngOnInit(): void {
    this.scrollToSection('section0');
  }

  scrollToSection(sectionId: string): void {
    if ('scrollTo' in window) {
      console.log('scrollTo existe')
      try {
        const element = document.getElementById(sectionId);
        console.log(element)
        if (element) {
          this.viewportScroller.scrollToAnchor(sectionId);
        }
      } catch (error) {
        console.error('Error al desplazarse a la sección:', error);
      }
    }
  }

  async desuscribirse() {

    try {
      const result = await axiosApi.post('/subscriptions/unsubscribe', { email: this.myForm.value.email });

      if (result.data) {
        this.isData = true;
        console.log('Desuscripción exitosa');
      } else {
        this.isData = false;
        console.log('Desuscripción fallida');
      }

    }
    catch (error) {
      this.isData = false;
      console.log(error);
    }
    finally {
      setTimeout(() => {
        this.isData = null;
        this.myForm.reset();
      }, 5000);
    }
  }

}
