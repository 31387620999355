<div class="modal-style h-100">
    <div style="height: 50%;">
        <img class="close-button" src="../../assets/img/close_black.svg" [mat-dialog-close]="true" />
        <h2 class="backgroundWhite pt-5 text-center title">Seguimiento de envío</h2>
        <div class="backgroundWhite pb-3 row justify-content-md-center text-center">
            <div class="pb-3 col-lg-9 justify-content-md-center">
                <input class="input" (input)="keyPress()" type="text" [(ngModel)]="codigo" required
                    placeholder="Ingresá el código de envío" minlength="6" maxlength="8" size="6">
            </div>
            <div class=" col-lg-9 justify-content-md-center">
                <button class="button" (click)="buscarSeguimiento()">Buscar envío</button>
            </div>

        </div>
    </div>

    <div *ngIf="envio" class="row justify-content-md-center text-left content" style="height: 50%;">
        <div class="w-75 content1">
            <!-- titulo -->
            <div class="pt-3 mb-4 col-lg-9 col-xs-12 title1 d-flex">
        
                    <img *ngIf="!noEncontrado" class="pr-3" src="../../assets/img/paquete.svg" />
                    <img style="vertical-align: bottom;" class="pr-3" *ngIf="noEncontrado" src="../../assets/img/close_red.svg" />
                    <label class="envio-title"> Envío {{noEncontrado ? 'no encontrado' : ''}}</label>

            </div>
            <!-- labels -->
            <div class="col-lg-9 col-xs-12 mb-2 labels d-flex justify-content-between" *ngIf="estados[2]">
        
                <div class="">
                    <img *ngIf="estados[2]?.realizado" src="../../assets/img/check-tenvio.svg" class="mr-2"/>

                    <label *ngIf="!estados[2]?.realizado"> Listo para retirar</label>
                    <label *ngIf="estados[2]?.realizado"> Envío retirado</label>
                </div>
                <div class="">
                    <label> {{estados[2]?.fecha?.toDate() | date: 'dd/MM/yy hh:mm' }} </label>
                </div>
            </div>

            <div class="col-lg-9 col-xs-12 labels d-flex justify-content-between" *ngIf="estados[2]?.realizado">
           
                <div class="">
                    <img *ngIf="estados[4]?.realizado" src="../../assets/img/check-tenvio.svg" class="mr-2" />

                    <label *ngIf="!estados[4]?.realizado"> En viaje</label>
                    <label *ngIf="estados[4]?.realizado"> Entregado</label>
                </div>
                <div class="">
                    <label> {{estados[4]?.fecha?.toDate() | date: 'dd/MM/yy hh:mm' }} </label>
                </div>
            </div>


        </div>

    </div>
</div>