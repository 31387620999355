import { Component, OnInit } from '@angular/core';
import { EnvioService } from 'src/core/services/envio.service';

@Component({
  selector: 'app-seguir-envio',
  templateUrl: './seguir-envio.component.html',
  styleUrls: ['./seguir-envio.component.scss']
})
export class SeguirEnvioComponent implements OnInit {

  codigo:string = '';
  envio;
  estados;
  noEncontrado:boolean = false;
  constructor(private envioService:EnvioService) { }

  async ngOnInit() {
    
  }

  async buscarSeguimiento() {
    this.envio = await this.envioService.getAsyncPaquetesByViaje(this.codigo.toUpperCase());
    this.estados = this.envio[0]?.estados  || []; 

    if(!this.estados || this.estados.length == 0){
      this.noEncontrado = true;
    }

  }

  keyPress(){
    this.noEncontrado = false;
    this.envio = undefined;
  }

}
