<section class="banner" #banner id="banner">
    <div class="row">
        <div class="col-md-12">
            <img src="../../assets/img/desuscribirse.webp" alt="desuscribirse" />
        </div>
    </div>
</section>

<section class="desuscribirse" id="section0">
    <div class="row">
        <div class="col-md-12">
            <h1>Te estás por desuscribir</h1>
            <br>
            <h2><strong>¡Importante!</strong></h2>
            <p>Queremos informarte sobre algunas consideraciones al desuscribirte de la App Tenvio:</p>
            <ul class="no-center">
                <li>Al desuscribirte, perderás el acceso permanente a la App Tenvio. Si decides volver a utilizar
                    nuestros servicios en el futuro, deberás realizar un nuevo registro.</li>
                <li>La desuscripción conlleva la eliminación total de tus datos de nuestra plataforma. Esto incluye la
                    pérdida irreversible de tu historial de movimientos y cualquier información asociada a tu cuenta.
                </li>
            </ul>
            <br>
            <h2><strong>¡Ten en cuenta!</strong></h2>
            <ul class="no-center">
                <li>Antes de desuscribirte, asegúrate de haber guardado la información relevante, ya que no podremos
                    recuperarla una vez que hayas completado el proceso de desuscripción.</li>
                <li>Es importante destacar que la eliminación de tus datos no es inmediata. Recibirás un correo
                    electrónico que confirmará la baja total y la eliminación de tu información.</li>
            </ul>
            <br>
        </div>

        <div class="col-md-12">
            <h3>
                Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos. Estamos aquí para ayudarte.
                <br>
                ¡Gracias por ser parte de la comunidad Tenvio!
            </h3>
            <br><br>
        </div>

        <div class="col-md-12 formulario">
            <form [formGroup]="myForm" (ngSubmit)="desuscribirse()" class="form-inline">
                <div class="form-group mx-sm-3 mb-2">
                    <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email">
                    <div *ngIf="myForm.get('email').hasError('email') && myForm.get('email').touched"
                        class="text-danger">
                        Ingrese un email válido.
                    </div>
                </div>
                <div>
                    <button type="submit" mat-flat-button class="relleno"
                        [disabled]="myForm.invalid">Desuscribirse</button>
                </div>
            </form>

            <!-- Mostrar mensajes según el estado de la desuscripción -->
            <div *ngIf="isData === true" class="text-success fade-in-text">
                Desuscripción exitosa. ¡Gracias por ser parte de la comunidad Tenvio!
            </div>
            <div *ngIf="isData === false" class="text-danger fade-in-text">
                <p>La desuscripción falló. Puede que el email ingresado no esté registrado en nuestra base de datos.<p>
                <p>Si el error persiste, por favor, contáctanos.</p>
            </div>
            <div *ngIf="isData === null || []" class="text-muted">
            </div>

        </div>
    </div>
</section>