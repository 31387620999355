import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  screenWidth: number;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  navigate(value) {

    let fragment = '';
    switch (value) {
      case 1:
        fragment = 'section1';
        break;
      case 2:
        fragment = 'seguridadSection';
        break;
      case 3:
        fragment = 'section3';
        break;
      case 4:
        fragment = 'preguntasFrecuentes';
        break;
      case 5:
        fragment = 'download';
        break;
      case 6:
        fragment = 'contacto';
        break;

      default:
        break;
    }

    this.router.navigate(['/home'], { fragment });

  }

  navigateTo(value) {
    this.router.navigate([value]);
  }

}
