import { Injectable, ɵConsole } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { Observable } from 'rxjs';

// const firebase = require('firebase')



@Injectable()
export class EnvioService {



    constructor(
        private firestore: AngularFirestore,
    ) {
    }

    async getAsyncPaquetesByViaje(codigo: string) {
        const paquetesRef = this.firestore.collection('paquetes').ref;
        const enviosRef = this.firestore.collection('envios').ref;

        const paqueteViajePrincipal = paquetesRef
            .where('codigo', '==', codigo)
            .orderBy("fecha", "desc").limit(1)
            .get();

        const [paquetesQuerySnapshot] = await Promise.all([paqueteViajePrincipal]);
        let paquetesResults = paquetesQuerySnapshot.docs.map((x: any) => {
            let data = x.data();
            data.uid = x.id;
            return data;
        });

        if(!paquetesResults || paquetesResults.length == 0){
            return [];
        }


        const envios = enviosRef
            .where('paquete', '==', paquetesResults[0].uid)
            .get();

        const [enviosQuerySnapshot] = await Promise.all([envios]);
        let enviosResults = enviosQuerySnapshot.docs.map((x: any) => {
            let data = x.data();
            data.uid = x.id;
            return data;
        });

        return enviosResults;
    }
}








