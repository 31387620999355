import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { CardDetailComponent } from '../card-detail/card-detail.component';


@NgModule({
  declarations: [HomeComponent,CardDetailComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    MatExpansionModule,
    IvyCarouselModule
  ]
})
export class HomeModule { }
