import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { data } from '../../assets/data/data';

import { data as dataSection3 } from '../../assets/data/dataSection3';
import { UserOpinionInterface } from '../card-detail/card-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { AotSummaryResolver } from '@angular/compiler';
import * as AOS from 'aos';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [trigger(
    'valueUpdated',
    [
      transition('void => *', []),   // when the item is created
      transition('* => void', []),   // when the item is removed
      transition('* => *', [
        style({ opacity: '0', transform: 'translateX(-10%)' }),
        animate(250, style({ opacity: '1', transform: 'translateX(0%)' })),
      ]),
    ])]
})
export class HomeComponent implements OnInit, AfterViewInit {

  public screenWidth: any;
  public screenHeight: any;
  public usersOpinions: any[];

  public indexSection3: number = 0;
  public section3Info: any = {};
  public dataSection3Length;
  public mobile = false;
  // ViewChilds
  @ViewChild('preguntasFrecuentes') preguntasFrecuentes: ElementRef;
  @ViewChild('comunidad') comunidad: ElementRef;
  @ViewChild('seguridadSection') seguridadSection: ElementRef;
  @ViewChild('section1') section1: ElementRef;
  @ViewChild('section3') section3: ElementRef;
  @ViewChild('myCarousel', { static: false }) myCarousel;
  @ViewChild('appCardDetail', { static: false }) appCardDetail;

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2
  ) { }


  ngAfterViewInit(): void {
    const iframe = this.renderer.selectRootElement('#iframe', true);
    const iframeContainer = this.renderer.selectRootElement('#iframe-container', true);

    // Habilitar el clic en el iframe cuando el usuario lo toca
    this.renderer.listen(iframeContainer, 'touchstart', () => {
      this.renderer.setStyle(iframe, 'pointer-events', 'auto');
    });

    // Deshabilitar el clic en el iframe cuando el usuario se desplaza fuera de él
    this.renderer.listen('document', 'touchmove', () => {
      this.renderer.setStyle(iframe, 'pointer-events', 'none');
    });

  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.usersOpinions = data as UserOpinionInterface[];
    this.dataSection3Length = dataSection3.length-1;
    this.indexSection3 = Math.floor(Math.random() * dataSection3.length);
    this.section3Info = dataSection3[this.indexSection3];
    if (window.screen.width <= 920) { // 768px portrait
      this.mobile = true;
    }

     AOS.init({
       duration: 400,
     })
     setTimeout(() => {AOS.refresh();}, 500);

  }




  toggle(event) {
    if (event.srcElement.src.includes("subs_icon")) {
      event.srcElement.src = "../../assets/img/plus_icon.svg";
    } else {
      event.srcElement.src = "../../assets/img/subs_icon.svg";
    }
  }

  changeSection3Info(direction) {

    switch (direction) {
      case 0:
        if (this.indexSection3 != 0) {
          this.indexSection3--
        }
        break;
      case 1:
        if (this.indexSection3 != this.dataSection3Length) {
          this.indexSection3++
        }
        break;

      default:
        this.indexSection3++
        break;
    }

    this.section3Info = dataSection3[this.indexSection3];

console.log(this.indexSection3);
console.log( this.section3Info);
  }

  handleCarouselEvents(event) {
  }


}
