import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LegalesComponent } from './legales/legales.component';
import { DesuscribirseComponent } from './desuscribirse/desuscribirse.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: 'home', component: HomeComponent },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'legales', component: LegalesComponent },
  { path: 'desuscribirse', component: DesuscribirseComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
