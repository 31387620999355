import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatToolbar } from '@angular/material/toolbar';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { getMobileOperatingSystem, sleep } from 'src/utils/Utils';
import { CardDetailDownloadAppComponent } from './card-detail-download-app/card-detail-download-app.component';
import { SeguirEnvioComponent } from './seguir-envio/seguir-envio.component';
import { HomeComponent } from './home/home.component';
import { LegalesComponent } from './legales/legales.component';
import * as AOS from 'aos';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'tenvio-web';
    public hide = true;
    public isMobile = false;
    @ViewChild('toolbar') toolbar: MatToolbar;
    activeComponenteRef;
    navigationSubscription;

    constructor(
        public scrollDispatcher: ScrollDispatcher,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.isMobile = this.detectMobile();
        this.scrollDispatcher.scrolled().subscribe((cdk: CdkScrollable) => {
            AOS.refresh();
            let offset = cdk.getElementRef().nativeElement.scrollTop || 0;

            if (offset > 50) {
                this.hide = false;
                this.cdr.detectChanges();
                this.toolbar._elementRef.nativeElement.style.height = '70px';
                this.toolbar._elementRef.nativeElement.style.transition = 'all 200ms ease-out';
            } else {
                this.hide = true;
                this.cdr.detectChanges();
                this.toolbar._elementRef.nativeElement.style.height = '90px';
                this.toolbar._elementRef.nativeElement.style.transition = 'all 200ms ease-out';
            }
        });

        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof ActivationEnd && e.snapshot.fragment) {
                this.scroollToSection(e.snapshot.fragment);
            }
        });
    }

    ngOnInit(): void {}

    private detectMobile(): boolean {
        return window.innerWidth <= 768;
    }

    openDialog() {
        const dialogRef = this.dialog.open(SeguirEnvioComponent, { hasBackdrop: true, restoreFocus: false, width: "610px", height: "520px", panelClass: 'custom-modalbox', backdropClass: "backdropClass" });
    }

    async fullPageScroll(value) {
        await this.router.navigate(['/', 'home']);
        let ref;
        await sleep(100);
        switch (value) {
            case 1:
                ref = this.activeComponenteRef.section1.nativeElement;
                break;
            case 2:
                ref = this.activeComponenteRef.seguridadSection.nativeElement;
                break;
            case 3:
                ref = this.activeComponenteRef.section3.nativeElement;
                break;
            case 4:
                ref = this.activeComponenteRef.preguntasFrecuentes.nativeElement;
                break;
            case 5:
                ref = this.activeComponenteRef.download.nativeElement;
                break;
            default:
                break;
        }
        ref.scrollIntoView({ behavior: 'smooth' });
    }

    async onActivate(event) {
        this.activeComponenteRef = event;
        const fragment = this.route.snapshot.fragment;
        await sleep(100);
        if (event instanceof LegalesComponent) {
            await sleep(100);
            this.activeComponenteRef.banner.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
        if (event instanceof HomeComponent && fragment) {
            await sleep(100);
            this.activeComponenteRef[fragment].nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    async scroollToSection(section) {
        let ref;
        await sleep(100);
        this.activeComponenteRef[section].nativeElement.scrollIntoView({ behavior: 'smooth' });
    }

    downloadApp() {
        let mobileOS = getMobileOperatingSystem();
        switch (mobileOS) {
            case 'Android':
                window.open('https://play.google.com/store/search?q=teenvio&hl=es_AR&gl=US', "_blank");
                break;
            case 'iOS':
                window.open('https://apps.apple.com/', "_blank");
                break;
            default:
                this.dialog.open(CardDetailDownloadAppComponent, { hasBackdrop: true, restoreFocus: false, width: "610px", height: "520px", panelClass: 'custom-modalbox', backdropClass: "backdropClass" });
                break;
        }
    }
}
