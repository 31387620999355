<mat-toolbar class="toolbar" #toolbar>
    <mat-toolbar-row>
        <img src="../assets/img/logo2.svg" alt="logo" class="logo">
        <span class="menu-spacer"></span>
        <a (click)="downloadApp()" class="relleno showDownloadButton">Descargar App</a>

        <div fxShow="true" fxHide.lt-md>
            <a (click)="fullPageScroll(1)" mat-flat-button>¿Cómo funciona?</a>
            <a (click)="fullPageScroll(2)" mat-flat-button>Seguridad</a>
            <a (click)="fullPageScroll(3)" mat-flat-button>¿Qué puedo enviar? </a>
            <a (click)="fullPageScroll(4)" mat-flat-button>Preguntas frecuentes</a>
            <a mat-flat-button (click)="openDialog()">Seguir mi envío</a>
            <a (click)="downloadApp()" mat-flat-button class="relleno">Descargar App</a>
            
            <!-- Nuevo ítem para móviles -->
            <a *ngIf="isMobile" mat-flat-button>Nuevo ítem</a>
        </div>
        <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm class="hamburguesa">
            <img src="../assets/img/menu1.svg" alt="menu" *ngIf="!sidenav.opened; else showCross" class="ham">
            <ng-template #showCross>
                <img src="../assets/img/close1.svg" alt="close" class="clo">
            </ng-template>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container fxFlexFill cdkScrollable>
    <mat-sidenav #sidenav mode="side" position="end">
        <mat-nav-list>
            <a (click)="fullPageScroll(1) && sidenav.toggle()" mat-list-item>¿Cómo funciona?</a>
            <a (click)="fullPageScroll(2) && sidenav.toggle()" mat-list-item>Seguridad</a>
            <a (click)="fullPageScroll(3) && sidenav.toggle()" mat-list-item>¿Qué puedo enviar?</a>
            <a (click)="fullPageScroll(4) && sidenav.toggle()" mat-list-item>Preguntas frecuentes</a>
            <a mat-list-item (click)="sidenav.toggle() && openDialog()">Seguir mi envío</a>
            
            <!-- Nuevo ítem para móviles -->
            <a *ngIf="isMobile" mat-list-item>
                <!-- enviarlo al whatsapp -->
                <a href="https://api.whatsapp.com/send?phone=549236652220&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Tenvío%20." target="_blank">Contacto - Whatsapp</a>
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>