<section class="footer">
    <div class="row max col-logo">
        <div class="col-md-12 text-center">
            <img src="../../assets/img/tenvioLogo.svg" alt="logo" class="logo" />
        </div>
        <div #download class="col-lg-12 text-center pt-2">
            <label> Descargá Tenvío hoy </label>
            <br />
            <p>
                La red colaborativa de envíos y viajeros frecuentes.<br />
                Envía y transportá más rápido y seguro.
            </p>
            <div class="row text-center m-auto pb-5 download">
                <div class="col-md-12 col-lg-6 hover">
                    <img src="../../assets/img/google-play.svg" alt="" />
                </div>
                <div class="col-md-12 col-lg-6 hover">
                    <img src="../../assets/img/apple-store.svg" alt="" />
                </div>
            </div>

            <div class="row col-lg-12 m-auto pt-5 max" *ngIf="screenWidth >= 960">
                <div class="col-lg-3 text-right logo-footer">
                    <img class="pb-5" src="../../assets/img/teenvioLogoXL.svg" alt="" />
                    <br />
                    <div class="footer-text-left text-left">
                        <span>© 2021 Tenvío.</span>
                        <br />
                        <span>Todos los derechos reservados.</span>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="footer-text-center text-left">
                        <label>Nosotros</label>

                        <div class="options">
                            <span (click)="navigate(1)">Cómo Funciona</span>
                            <br />
                            <span (click)="navigate(2)">Seguridad</span>
                            <br />
                            <span (click)="navigate(3)">Qué enviar</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="footer-text-center text-left">
                        <label>Ayuda</label>

                        <div class="options">
                            <span (click)="navigate(4)">Preguntas</span>
                            <br />
                            <span (click)="navigate(4)">Frecuentes</span>
                            <br />
                            <span (click)="navigate(6)">Asistencia</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="footer-text-center text-left">
                        <label>Legales</label>

                        <a routerLink="/legales">
                            <div class="options">
                                <span>Términos y</span>
                                <br />
                                <span>condiciones</span>
                                <br />
                            </div>
                        </a>
                        <a href="/desuscribirse">
                            <div class="options">
                                <span>Darse de baja</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col">
                    <div class="footer-text-right text-left">
                        <label>Seguinos</label>

                        <div class="row">
                            <div class="col-4">
                                <a href="https://instagram.com/tenvio_argentina" target="_blank">
                                    <img class="redes-icons" src="../../assets/img/instagramIcon.svg" alt="" />
                                </a>
                            </div>

                            <div class="col-4">
                                <a href="https://ar.linkedin.com/in/TenvioArgentina" target="_blank">
                                    <img class="redes-icons" src="../../assets/img/lnIcon.svg" alt="" />
                                </a>
                            </div>

                            <div class="col-4">
                                <a href="https://facebook.com/TenvioArgentina" target="_blank">
                                    <img class="redes-icons" src="../../assets/img/faceIcon.svg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row col-lg-12 m-auto pt-5" *ngIf="screenWidth < 960">
                <div class="col-6">
                    <div class="footer-text-center text-left">
                        <label>Nosotros</label>

                        <div class="options">
                            <span (click)="navigate(1)">Cómo Funciona</span>
                            <br />
                            <span (click)="navigate(2)">Seguridad</span>
                            <br />
                            <span (click)="navigate(3)">Qué enviar</span>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="footer-text-center text-left">
                        <label>Ayuda</label>

                        <div class="options" (click)="navigate(4)">
                            <span>Preguntas</span>
                            <br />
                            <span>Frecuentes</span>
                            <br />
                            <span>Asistencia</span>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="footer-text-center text-left">
                        <label>Legales</label>

                        <a routerLink="/legales">
                            <div class="options">
                                <span>Términos y</span>
                                <br />
                                <span>condiciones</span>
                                <br />
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-6">
                    <div class="footer-text-right text-left">
                        <label>Seguinos</label>

                        <div class="social-icons">
                            <a href="https://instagram.com/tenvio_argentina" target="_blank">
                                <img class="redes-icons" src="../../assets/img/instagramIcon.svg" alt="" />
                            </a>

                            <a href="https://ar.linkedin.com/in/TenvioArgentina" target="_blank">
                                <img class="redes-icons" src="../../assets/img/lnIcon.svg" alt="" />
                            </a>

                            <a href="https://facebook.com/TenvioArgentina" target="_blank">
                                <img class="redes-icons" src="../../assets/img/faceIcon.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bar"></div>

    <div class="mark">
        <a href="https://brandal.ar/" target="blank"
            ><img src="../../assets/img/brandal.png" alt="brandal" class="brandal"
        /></a>
        <!-- <a href="https://ultimasoluciones.com" target="blank">
            <img src="../../assets/img/prixel.png" alt="prixel" class="prixel"
        /></a> -->
    </div>
</section>
