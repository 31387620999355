export const data = [
    {
        "title": "Tomás",
        "date": "9 de abril de 2021",
        "content": "Me la recomendó un amigo. Necesitaba mandarle unos papeles importantes a mi papá. Tenía muy poco tiempo para resolver el envío por correo tradicional. <br> ¡Me SALVARONNN!",
        "path": "../../assets/avatar/1.svg"
    },
    {
        "title": "Luciana",
        "date": "18 de junio de 2021",
        "content": "Quería sorprender a mis hijos que viven en Rosario. No era simplemente enviar una encomienda más, necesitaba tener la seguridad que iban a tratar a mi envío como si fuera propio. Martin fue mi viajero elegido, la verdad ¡estoy ENCANTADA! <br> Muy fácil de usar y recibí de Martín minuto a minuto de la encomienda",
        "path": "../../assets/avatar/2.svg"
    },
    {
        "title": "Sergio",
        "date": "22 de abril de 2021",
        "content": "¡Muy simple de usar! <br> Llevo publicados viajes y entregados varios envíos. ¡Esta bueno para hacer $ extras en cada viaje!",
        "path": "../../assets/avatar/3.svg"
    },
    {
        "title": "Gisela",
        "date": "14 de mayo de 2021",
        "content": "Por mi trabajo estoy constantemente evaluando plataformas que gestionen y resuelvan envíos. ¡Los envíos llegan perfectos, casi como si los hubiera trasladado yo!",
        "path": "../../assets/avatar/4.png"
    },
    {
        "title": "Osvaldo",
        "date": "12 de junio de 2021",
        "content": "Probé Tenvío y me gustó mucho. Soy comisionista, para mí es una buena herramienta para que me encuentren y poder llevar más cosas en cada viaje.",
        "path": "../../assets/avatar/5.png"
    },
    {
        "title": "Sol",
        "date": "9 de abril de 2021",
        "content": "Compré una heladera usada por Facebook y no tenía como traerla hasta mi casa. La publiqué en Tenvío y en un ratito ya conseguí quien me la traiga. Estoy chocha 😊!!!!",
        "path": "../../assets/avatar/6.svg"
    },
    {
        "title": "Victoria",
        "date": "11 de mayo de 2021",
        "content": "Necesitaba hacer una mudanza y un conocido me mencionó Tenvío. Me registré, publiqué mi envío y en menos de dos días ya había coordinado condiciones del viaje, tarifas y día de mudanza con mi viajero de confianza. Me dió seguridad encontrarme con viajeros calificados y tarifas de viajes accesibles.",
        "path": "../../assets/avatar/7.svg"
    },
    {
        "title": "Pedro",
        "date": "30 de junio de 2021",
        "content": "Ando con mi moto por trabajo en la ciudad, descargue Tenvío y me resulto práctica para publicar mi recorrido y hacerme un ingreso extra llevando cosas o haciendo trámites.",
        "path": "../../assets/avatar/9.png"
    }
]
